<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      :hideCompanyChip="true"
      v-if="!fetchinData"
    />

    <v-row>
      <v-col>
        <v-card tile>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("phrases.Page") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td class="labelOnly">Firmenname</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.name"
                      itemName="name"
                      :route="patchRoute"
                      :permissionsWrite="writePerm"
                      inputElement="input"
                      inputType="text"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>

                <tr>
                  <td class="labelOnly">{{ $t("phrases.legalFormName") }}</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.legalForm"
                      itemName="legalForm"
                      :route="patchRoute"
                      :permissionsWrite="writePerm"
                      inputElement="select"
                      :selectOptions="legalForms"
                      itemValueKey="id"
                      itemTextKey="name"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>

                <tr>
                  <td>{{ $t("phrases.businessCategoryName") }}</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.businessCategory"
                      itemName="businessCategory"
                      :route="patchRoute"
                      :permissionsWrite="writePerm"
                      inputElement="select"
                      :selectOptions="businessCategories"
                      itemValueKey="id"
                      itemTextKey="name"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import DataPatcher from "@/components/ui/DataPatcher";
export default {
  name: "Company",

  components: {
    PageHeader,
    AreYouSureDialog,
    DataPatcher,
  },

  data() {
    return {
      companyUuid: this.$route.params.id,
      pageTitle: null,
      pageDescription: null,
      fetchinData: false, // should be true when fetching data
      legalForms: [],
      businessCategories: [],
      auth: [],
      data: [],
      componentKey: 0,
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
      typeToDelete: null,
      patchRoute: `/manager/companies/${this.$route.params.id}`,
    };
  },

  props: [],

  created: function () {
    this.getData();
  },

  methods: {
    async getLegalForms() {
      let response = await this.get(`/public/companies/legalforms`);

      if (response.status === 200) {
        this.legalForms = response.data.data;
      }

      this.getBusinessCategories();
    },

    async getBusinessCategories() {
      let response = await this.get(`/public/companies/businesscategories`);

      if (response.status === 200) {
        this.businessCategories = response.data.data;
      }

      this.fetchinData = false;
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(`/manager/companies/${this.companyUuid}`);

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
      }

      this.getLegalForms();
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
